/* SolucionesCarousel.css */

/* Contenedor de los botones */
.buttons-container {
  display: flex; /* Hace que los botones estén en fila */
  justify-content: center; /* Centra los botones horizontalmente */
  gap: 10px; /* Añade un espacio de 10px entre los botones */
  margin: 20px 0; /* Añade márgenes superior e inferior para separar el grupo de botones del contenido */
  flex-wrap: wrap; /* Permite que los botones se envuelvan en pantallas pequeñas */
}

/* Estilos de los botones */
.buttons-container .btn-primary {
  background-color: #a01a58; /* Morado */
  border-color: #894c8c; /* Morado */
  color: white;
  padding: 10px 20px; /* Tamaño uniforme de los botones */
  text-align: center; /* Centra el texto en los botones */
  min-width: 120px; /* Define un tamaño mínimo para los botones */
  flex: 1 1 auto; /* Permite que los botones se ajusten según el tamaño del contenedor */
  max-width: 200px; /* Define un tamaño máximo para los botones */
  box-sizing: border-box; /* Incluye el padding y el borde en el tamaño total del botón */
}

/* Estilo de los botones al pasar el ratón */
.buttons-container .btn-primary:hover {
  background-color: #894c8c; /* Un morado más oscuro al pasar el ratón */
  border-color: #894c8c;
}

/* Estilo de los botones al hacer clic o tener el foco */
.buttons-container .btn-primary:focus,
.buttons-container .btn-primary:active {
  background-color: #894c8c; /* Asegura que el color no cambie al seleccionar */
  border-color: #894c8c;
  box-shadow: none;
}

/* Media Queries para pantallas más pequeñas */
@media (max-width: 600px) {
  .buttons-container {
    flex-direction: column; /* Cambia la disposición a columna en pantallas pequeñas */
    align-items: stretch; /* Estira los botones para que ocupen todo el ancho disponible */
  }

  .buttons-container .btn-primary {
    width: 100%; /* Asegura que los botones ocupen todo el ancho del contenedor en pantallas pequeñas */
    min-width: unset; /* Elimina el tamaño mínimo para que los botones puedan ajustarse */
  }
}
