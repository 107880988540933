.navbar {
  display: flex;
  justify-content: space-between; /* Separa el logo de los enlaces */
  align-items: center;
  padding: 1rem;
  background-color: #333; 
  position: fixed; 
  width: 100%;
  top: 0;
  z-index: 1000;
}
.NavBarProyect{
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  padding: 1rem;
  background-color: #333; 
  position: fixed; 
  width: 100%;
  top: 0;
  z-index: 1000;
}

.navbar .logo {
  height: 50px;
  margin-right: auto; /* Empuja el logo hacia la izquierda */
  z-index: 1001;
}

.logo {
  height: 50px;
}



.navbar-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin-left: auto; 
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  position: relative;
}

.navbar-links li a::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 4px;
  display: block;
  background: #f6f4f5; 
  transition: width 0.3s, box-shadow 0.3s;
  bottom: -4px; 
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 8px rgba(203, 57, 186, 0.611); 
}

.navbar-links li a:hover::after {
  width: 100%;
  box-shadow: 0 0 12px rgba(0, 255, 0, 0.7); /* Efecto neón al pasar el cursor */
}

 


/* Estilo responsive */
.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-links {
    flex-direction: column;
    gap: 10px;
    display: none; /* El menú está oculto inicialmente */
  }
  .navbar-links.open {
    display: flex; /* El menú se muestra cuando tiene la clase 'open' */
  }

  .navbar-links li {
    width: 100%;
    text-align: center;
  }

  .navbar-links li a {
    font-size: 16px; 
    padding: 8px 10px; 
  }
  .menu-toggle {
    display: block; /* Mostrar el botón hamburguesa en pantallas pequeñas */
  }

  

}



