/* Marcas.css */

/* Contenedor principal de la sección */
.full-width-container {
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
    padding: 20px; /* Añade un poco de espacio alrededor del contenido */
    background-color: #fd00fd29; /* Fondo morado para la sección */
}

/* Contenedor de las certificaciones */
.certificaciones-content {
    text-align: center; /* Centra el título y el contenido */
}

/* Título de las certificaciones */
.certificaciones-title {
    margin-bottom: 20px; /* Espacio debajo del título */
    font-size: 30px; /* Tamaño del texto del título */
    color: #090101; /* Color del texto del título en blanco para contrastar con el fondo morado */
}

/* Contenedor de las imágenes de certificación */
.certificaciones-container {
    display: flex;
    flex-direction: row; /* Coloca las imágenes en una fila */
    align-items: center; /* Centra las imágenes verticalmente en la fila */
    gap: 15px; /* Espacio entre las imágenes */
    flex-wrap: wrap; /* Permite que las imágenes se ajusten a la siguiente línea si no caben en una sola fila */
    justify-content: center; /* Centra las imágenes horizontalmente */
}

/* Estilo para las imágenes de certificación */
.certificacion-image {
    display: flex;
    justify-content: center; /* Centra la imagen dentro del contenedor */
    align-items: center; /* Centra la imagen verticalmente dentro del contenedor */
    width: 100px; /* Ancho del contenedor de la imagen */
    height: 100px; /* Altura del contenedor de la imagen */
    border-radius: 50%; /* Hace que el contenedor sea circular */
    overflow: hidden; /* Oculta cualquier contenido que se desborde del contenedor circular */
    background-color: #fff; /* Fondo blanco para los contenedores circulares */
    border: 2px solid #ddd; /* Borde opcional para las imágenes */
}

/* Estilo para las imágenes dentro del contenedor circular */
.certificacion-image img {
    max-width: 100%; /* Asegura que la imagen no se salga del contenedor */
    max-height: 100%; /* Asegura que la imagen no se salga del contenedor */
    object-fit: contain; /* Ajusta la imagen para que se mantenga dentro del contenedor */
}
