
#proyect {
  padding: 40px 20px;
  text-align: center;
  background-color: #ffffff; 
}

#proyect h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.project-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(127, 4, 55, 0.2);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgb(105, 3, 60);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-info {
  padding: 20px;
  text-align: left;
}

.project-info h3 {
  margin-top: 0;
  color: #333;
}

.project-info p {
  margin-bottom: 0;
  color: #666;
}
/**/
/* Contenedor con sombra morada */

#contact-wrapper {
  box-shadow: 0px 0px 30px rgba(145, 0, 255, 0.5); /* Sombra morada tenue */
  border-radius: 10px; /* Esquinas redondeadas opcionales */
  margin: 2.54rem auto; /* Centrar el contenedor y añadir margen superior */
  padding: 20px;
  max-width: 90%; /* Ajusta el tamaño máximo del contenedor según sea necesario */
  background-color: white; /* Fondo blanco para destacar la sombra */
  box-sizing: border-box; /* Incluye padding en el tamaño total */
}

/* Contacto */
#contact {
  background-image: url('../assets/FONDO.png'); /* Reemplaza con la ruta de tu imagen de fondo */
  background-size: cover; /* Ajusta la imagen para que cubra todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  padding: 20px; /* Opcional: añade relleno si es necesario */
  color: white; /* Opcional: cambia el color del texto si la imagen de fondo es oscura */
  text-align: center; /* Centra el texto en el contenedor */
  border: 5px solid #6d1d65; /* Borde morado fuerte de 5px de grosor */
  border-radius: 10px; /* Opcional: añade bordes redondeados */
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

/* Ajuste para flexibilidad y responsividad */
.contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 calc(50% - 20px); /* Ajuste para que dos columnas quepan en la mayoría de pantallas */
  margin: 10px;
  max-width: calc(50% - 20px); /* Asegura que cada columna ocupe la mitad del contenedor */
}

.contact-item img.contact-icon {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.contact-item h3 {
  margin: 0;
}

.contact-item p {
  margin: 5px 0 0;
}

.elegant-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  background-color: #e10ef4;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.elegant-button:hover {
  background-color: #ff1db7;
}

/* Media Queries para pantallas más pequeñas */
@media (max-width: 768px) {
  .contact-item {
    flex: 1 1 100%; /* Acomoda a una columna en pantallas pequeñas */
    max-width: 100%;
  }
}

#nosotros {
  background-color: #6d1d65; /* Color de fondo opcional */
  padding: 40px 0; /* Espaciado superior e inferior */
  text-align: center; /* Centra el contenido dentro de la sección */
  margin-top: 0px; /* Ajusta según la altura de la barra de navegación, reducido en 1 pulgada (2.54 cm) */
}

.banner-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  border: 1cm solid #6d1d65; /* Marco morado de 0.5 cm de grosor */
  box-shadow: none; /* Elimina la sombra del contenedor */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Añade una sombra de borde */
}

.banner-image {
  width: 100%;
  height: auto; /* Mantiene la relación de aspecto */
  border-radius: 0px; /* Opcional: añade bordes redondeados a la imagen */
  box-shadow: none; /* Elimina la sombra de la imagen */
}

.elegant-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  text-decoration: none;
  color: rgb(253, 22, 222);
  background-color: #e10ef4;
  border-radius: 80px;
  transition: background-color 0.3s ease;
}

.elegant-button:hover {
  background-color: #ff1db7;
}
